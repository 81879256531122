import CallMadeIcon from "@material-ui/icons/CallMade";
import lynkitIcon from "../images/lynkit_logo.png";

import blogimg from "../images/blog1img.jpg";
import { useHistory } from "react-router-dom";

export default function Blog() {
	const history = useHistory();

	let blogs = [
		{
			blogUrl: "/blog/what-is-transport-management-system-tms",
			title: "Transport Management System (TMS) : A Guide For Transporters",
			imgUrl: lynkitIcon,
			summary:
				"Transporters in the logistics industry today face numerous challenges, from managing operational costs to ensuring timely deliveries. However, with a robust Transport Management System (TMS), transporters can streamline their operations and feel reassured about the efficiency of their processes. ",
			date: "Lynkit July 01, 2024",
		},
		{
			blogUrl: "/blog/what-is-warehouse-management-system-wms",
			title: "Warehouse Management System (WMS) - Why Is It Important?",
			imgUrl: blogimg,
			summary:
				"Before explaining what a warehouse management system (WMS) is, let's set the context from the past. In the early 2000s, Toys R Us was the leading toy manufacturer, and e-retail was rapidly growing.",
			date: "Lynkit April 29, 2024",
		},
	];
	return (
		<section className="blog_page">
			<section className="blog_discover">
				<h1>Discover our latest blog</h1>
				<div className="blog_item_wrap">
					{blogs.map((_, i) => {
						return (
							<div className="blog_item" key={i}>
								<div className="blog_img">
									<img src={_.imgUrl} alt="blog img" />
								</div>
								<div className="blog_info">
									<div className="blog_title" onClick={() => history.push(_.blogUrl, { index: i })}>
										{_.title}
									</div>
									<div className="blog_summary">{_.summary}</div>
									<div className="blog_meta">
										<div className="blog_date">
											<div>
												<img src={lynkitIcon} alt="" />
											</div>
											{_.date}
										</div>
										<div className="blog_link" onClick={() => history.push(_.blogUrl, { index: i })}>
											Read More
											<CallMadeIcon style={{ fontSize: "1rem" }} />
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</section>
			{/* <section className="blog_explore">
				<h4>Explore More</h4>
				<div className='explore_item_wrap'>
					<div className="explore_item">
						<div className="explore_img">
							<img src="" alt="expllore img" />
						</div>
						<div className="explore_text">
                            Enhancing The logistics
                        </div>
					</div>
				</div>
			</section> */}
		</section>
	);
}
